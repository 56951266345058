import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

const ContactForm = ({show}) => {
    const formStyle = { fontSize: '2.4rem' };
    return (
        <form name="contact" style={{display : show? 'block' : 'none'}} className="mt-5" action="/success"
            method="post" netlify-honeypot="bot-field" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <Row className="justify-content-md-center">
                <Col md="8">
                    <Row noGutters="true">
                        <Col md="6">
                            <Form.Control type="text" style={formStyle} placeholder="Nome" id="input_name" name="name" />
                        </Col>
                        <Col md="6">
                            <Form.Control type="email" style={formStyle} placeholder="e-mail" id="input_email" name="email" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-md-center mb-4">
                <Col md="8">
                    <Form.Control as="textarea" style={formStyle} id="input_message" name="message" placeholder="Menssagem..." rows="10" />
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Button type="submit" variant="outline-light" style={{ fontSize: '2.4rem', fontWeight: "bold" }}>Enviar</Button>
            </Row>
        </form>
    )
}

export default ContactForm;