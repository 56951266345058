import React, { useContext, useReducer, useState } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import ContactForm from '../ContactForm/ContactForm';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;
  const [showForm, setShowForm] = useState(false);

  return (
    <section id="contact">
      <Container>
        <Title title="Contato" />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {cta || 'Would you like to work with me? Awesome!'}
            </p>
            <span className="cta-btn cta-btn--resume" style={{cursor : "pointer"}}>
              <a onClick={() => {setShowForm(true)}}>
                {btn || 'Know more'}
              </a>
            </span>
            {/* <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={email ? `mailto:${email}` : 'https://github.com/cobidev/react-simplefolio'}
            >
              {btn || "Let's Talk"}
            </a> */}
            <ContactForm show={showForm} />
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
